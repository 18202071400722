@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100vh;
}

.styled-scroll::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	border-radius: 10px;
	background-color: #F5F5F5;
}

.styled-scroll::-webkit-scrollbar
{
	width: 6px;
  height: 6px;
	background-color: #F5F5F5;
}

.styled-scroll::-webkit-scrollbar-thumb
{
	border-radius: 10px;
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
	background-color: #E2E8F0;
}

:root{
  --toastify-color-success: #D1FAE5;
  --toastify-text-color-success: #334155;
  --toastify-text-color-error: #334155;
  --toastify-color-error: #FED7D7;
  --toastify-icon-color-success: #10B981 !important;
  --toastify-icon-color-error: #E53E3E !important;
}
.Toastify__toast--error{
  div{
    div{
      svg{
        color: red;
      }
    }
  }
}

.Toastify__toast-icon{
  color: #10B981;
}
.Toastify__close-button{
color: #334155;
}

.Toastify__toast-container {
font-size: 15px;
}

.back-drop{
  background: url("../assets/images/auth-background.svg");
  background-position: right;
  background-repeat: no-repeat;
  background-size: contain;
}

.e-datetimepicker.e-popup .e-list-parent.e-ul .e-list-item.e-active{
  color: #F37A26 !important;
  background-color: #FEF7EE;
  font-weight: 500;
}

.e-today{
color: #F37A26 !important;
}
.e-btn.e-today:hover{
  color: #F37A26 !important;
  background-color: #FEF7EE !important;
}

.e-today>span{
border-color: #F37A26 !important;
color: #F37A26 !important;
}

.e-cell.e-selected.e-focused-date>span{
  background-color: #F37A26 !important;
  color: white !important;
}

/* Remove default password input icon */
input[type="password"] {
  -webkit-text-security: none;
}

/* Hide default password input icon in Firefox */
input[type="password"]::-ms-reveal,
input[type="password"]::-ms-clear {
  display: none;
}

/* Hide default password input icon in IE */
input[type="password"]::-ms-reveal,
input[type="password"]::-ms-clear {
  display: none !important;
}

/* Hide default password input icon in Edge */
input[type="password"]::-ms-reveal,
input[type="password"]::-ms-clear {
  display: none !important;
}

/* Hide default password input icon in Chrome and Safari */
input[type="password"]::-webkit-contacts-auto-fill-button {
  visibility: hidden;
  display: none !important;
}

@import "../../node_modules/@syncfusion/ej2-base/styles/material.css";
@import "../../node_modules/@syncfusion/ej2-buttons/styles/material.css";
@import "../../node_modules/@syncfusion/ej2-lists/styles/material.css";
@import "../../node_modules/@syncfusion/ej2-inputs/styles/material.css";
@import "../../node_modules/@syncfusion/ej2-popups/styles/material.css";
@import "../../node_modules/@syncfusion/ej2-react-calendars/styles/material.css";