:root {
    --checkbox: #FFFFFF;
    --checkbox-disabled-border: #D1D5DB;
    --checkbox-checkmark: #D1D5DB;

    /* for red color */
    --checkbox-red: #FF4F3F;

    /* for yellow color */
    --checkbox-yellow-400: #FDDBB6;
    --checkbox-yellow-500: #F37A26;

  }
  
  /* The container */
  .checkbox {
    // font-family: var(--font-family);
    font-size: 14px;
    min-height: 16px;
    padding-left: 16px;
    position: relative;

  
    /* Default checkbox input field */
    input {
      cursor: pointer;
      height: 16px;
      left: 0;
      margin: 0;
      opacity: 0;
      position: absolute;
      top: 50%;
      transform: translate(0, -50%);
      width: 100%;
      z-index: 999;

      /* On mouse-over for*/
      &:hover ~ .checkbox--checkmark {
        &-red{
          border: 0;
          box-shadow: inset 0 0 0 2px var(--checkbox-red) !important;
        }
        &-yellow{
          border: 0;
          box-shadow: inset 0 0 0 2px var(--checkbox-yellow-400) !important;
        }
      }

      /* On focus */
      &:focus ~ .checkbox--checkmark {
        &-red{
          outline-offset: 1px;
          outline: 1px solid var(--checkbox-red);
        }
        &-yellow{
          outline-offset: 1px;
          outline: 1px solid var(--checkbox-yellow-400);
        }
      }

      ~ .checkbox--checkmark {
        &-red{
          border: 0;
          box-shadow: inset 0 0 0 2px var(--checkbox-red) !important;

        }
      }
  
      /* On checked or Indeterminate */
      &:checked ~ .checkbox--checkmark,
      &:indeterminate ~ .checkbox--checkmark {
        &-red{
          // background-color: var(--checkbox-red);
          box-shadow: none;
        }
        &-yellow{
          background-color: var(--checkbox-yellow-500);
          box-shadow: none;
        }
      }
  
      /* Checked Indicator */
      &:checked ~ .checkbox--checkmark-red:after,
      &:checked ~ .checkbox--checkmark-yellow:after
      {
        visibility: visible;
      }
  
      /* Indeterminate Indicator */
      &:indeterminate ~ .checkbox--checkmark-red:after,
      &:indeterminate ~ .checkbox--checkmark-yellow:after
      {
        visibility: visible;
      }
  
      /* Checked and disabled */
      &:checked,
      &:disabled ~ .checkbox--checkmark-red,
      &:disabled ~ .checkbox--checkmark-yellow
      {
        // opacity: 0.5;
        box-shadow: 0 0 0 1px var(--checkbox-disabled-border) !important;
        background-color: #F2F4F7;
      }

      &:disabled ~ .checkbox--checkmark-red,
      &:disabled ~ .checkbox--checkmark-yellow {
        opacity: 0.8;
      }
  
      /* Checked and disabled checkmark */
      &:checked:disabled ~ .checkbox--checkmark-:after,
      &:checked:disabled ~ .checkbox--checkmark-yellow:after
      {
        border: solid var(--checkbox-disabled-border);
        border-width: 0 2px 2px 0;
        visibility: visible;
      }
  
      /* disabled cursor */
      &:disabled {
        cursor: not-allowed;
      }
    }
  
    /* Create a custom checkbox */
    &--checkmark-red,
    &--checkmark-yellow
     {
      background-color: var(--checkbox);
      border-radius: 3px;
      box-shadow: inset 0 0 0 1px var(--checkbox-checkmark);
      height: 16px;
      left: 0;
      position: absolute;
      top: 50%;
      transform: translate(0, -50%);
      transition: box-shadow 0.2s ease-in-out 0s, border-color 0.2s ease-in-out 0s;
      transition-duration: 0.1s;
      transition-timing-function: ease-in-out;
      width: 16px;
  
      /* Checkmark indicator */
      &:after {
        border: solid var(--checkbox);
        border-bottom-width: 2px;
        border-left-width: 0;
        border-right-width: 2px;
        border-top-width: 0;
        content: '';
        height: 7px;
        left: 6px;
        position: absolute;
        top: 4px;
        transform: rotate(45deg);
        visibility: hidden;
        width: 4px;
      }
    }
  
    /* Checkmark indicator / is Indeterminate */
    &--is-indeterminate {
      .checkbox--checkmark-red:after,
      .checkbox--checkmark-yellow:after
       {
        height: 0;
        left: 4px;
        top: 7px;
        transform: rotate(0);
        width: 8px;
      }
    }
  }
  